<template>
    
	<div class="cont" id="p20">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('c'); search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('c'); search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
							<div class="notice">최대 3개월 이내에서 조회할 수 있습니다.</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select 
								v-model="search.status"
								@change="getSummary"
							>
								<option
									v-for="(item, index) in codes.payments.status"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">서비스</div>
						<div class="content">
							<select 
								v-model="search.serviceType"
								@change="getData"
							>
								<option value="">전체</option>
								<option
									v-for="(item, index) in codes.serviceType"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">상품 금액</div>
						<div class="content">
							<input type="number" placeholder="최저 가격"
								v-model="search.startProductPrice"
								:rules="[rules.max(search, 'startProductPrice', 10)]"
								style="width: 45%;"
							/>
							-
							<input type="number" placeholder="최고 가격"
								v-model="search.endProductPrice"
								:rules="[rules.max(search, 'endProductPrice', 10)]"
								style="width: 45%;"
							/>
						</div>
					</div>
					<div class="c-item">
						<div class="title">결제카드</div>
						<div class="content">
							<select 
								v-model="search.cardType"
								@change="getData"
							>
								<option
									v-for="(item, index) in codes.payments.cardType"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">할부</div>
						<div class="content">
							<select 
								v-model="search.installment"
								@change="getData"
							>
								<option
									v-for="(item, index) in codes.payments.installment"
									:key="index"
									:value="item.code"
									>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="결제상품, TID, 구매자의 연락처"
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="'완료 및 취소'"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
						
				</div>
			</div>
			<div class="right" style="width: 360px;">
				<div class="group g04" style="height: 120px; margin-bottom: 10px;">
					<div class="item">
						<div class="tit" style="margin: 20px 0">
							<img src="@/assets/img/icon-01.png" alt="">
							<div class="desc">결제 완료</div>
						</div>
						<div class="content">
							<div class="amount">
								<span class="cnt">{{ summary.completeCount | makeComma}}</span>건 
								<span class="float-right">
									<span class="cnt">{{ summary.completeAmount | makeComma}}</span>원
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="group g04" style="height: 120px; background-color: #FAAC58;">
					<div class="item">
						<div class="tit" style="margin: 20px 0">
							<img src="@/assets/img/icon-01.png" alt="">
							<div class="desc">결제 취소</div>
						</div>
						<div class="content">
							<div class="amount">
								<span class="cnt">{{ summary.cancelCount | makeComma}}</span>건 
								<span class="float-right">
									<span class="cnt">{{ summary.cancelAmount | makeComma}}</span>원
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="c02">
			<div class="table type09">
				
				<div 
					v-if="items.length"
					class="text-right mt-10 mb-10">
					<Pagination 
						:options="search"
					/>
				</div>
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">서비스 구분</div>
						<div class="td">TID</div>
						<div class="td">상품명</div>
						<div class="td">결제카드</div>
						<div class="td">카드번호</div>
						<div class="td">결제금액</div>
						<div class="td">할부</div>
						<div class="td">구매자 연락처</div>
						<div class="td">거래일시</div>
						<div class="td">취소일자</div>
						<div class="td">상태</div>
						<div class="td">상세보기</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.payChnCateName }}</div>
						<div class="td">{{ item.terminalNo }}</div>
						<div class="td text-left">{{ item.paymentProductName }}</div>
						<div class="td">{{ item.cardName }}</div>
						<div class="td">{{ item.cardNumber }}</div>
						<div class="td">{{ item.tradAmount | makeComma }}원</div>
						<div class="td">{{ item.installment }}</div>
						<div class="td">{{ item.buyerContact | makeTell }}</div>
						<div class="td">{{ item.approvalDate | transDateTime }}</div>
						<div class="td">{{ item.cancelDate | transDate }}</div>
						<div class="td"></div>
						<div class="td justify-center">
							<v-btn 
								small 
								class="success2" 
								:to="{ name: 'PaymentsHistoryDetail', params: { index: item.tranSeq } }"
							>상세보기</v-btn>
						</div>
					</div>
					<div
						v-show="!items.length"
					>
						<div class="no-data">No Data</div>
					</div>
				</div>
				
				<div 
					v-if="items.length"
					class="text-right mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { rules } from '@/resources/rules/rules'
	
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[0].list[0].name
				}
				,summary: {
					paymentCount: 0
					,paymentAmount: 0
					,cancelCount: 0
					,cancelAmount: 0
				}
				,items: []
				,excel_items: []
				,search: {
					inquiryStartDate: ''
					,inquiryEndDate: ''
				}
				,dateType: 'c'
				,today: date.getToday()
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,rules: rules
				,columns: [
					{ label: 'No', field: 'rowNumber'}
					,{ label: '서비스 구분', field: 'payChnCateName'}
					,{ label: 'TID', field: 'terminalNo'}
					,{ label: '상품명', field: 'paymentProductName'}
					,{ label: '결제카드', field: 'cardName'}
					,{ label: '카드번호', field: 'cardNumber'}
					,{ label: '결제번호', field: 'tranSeq'}
					,{ label: '결제금액', field: 'tradAmount'}
					,{ label: '할부', field: 'installment'}
					,{ label: '구매자 연락처', field: 'buyerPhoneNumber'}
					,{ label: '거래일시', field: 'approvalDate'}
					,{ label: '취소일자', field: 'cancelDate'}
					,{ label: '상태', field: 'messageType'}
				]
			}
		}
		,filter: filter
		,methods: {
			getSummary: async function(){
				const search = this.search
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/summary'
						,data: search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
						await this.getData()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getData: async function(){
				const search = this.search
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/history'
						,data: search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,setSearchDate: async function(type){
				this.dateType = type
				if(type == 'd'){
					this.search.inquiryStartDate = date.getToday()
					this.search.inquiryEndDate = date.getToday()
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else{
					this.search.inquiryStartDate = date.getSearchBaseDate()
					this.search.inquiryEndDate = date.getToday()
				}
				
				await this.getSummary()
			}
			,clear: function(){
				this.search = {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: ''
					,keyword: ''
					,messageType: ''
					,payChnCate: ''
					,list_cnt: 20
					,status: ''
					,serviceType: ''
					,cardType: ''
					,installment: ''
				}
				this.setSearchDate('c')
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/history/download'
						,data: {
							inquiryStartDate: this.search.inquiryStartDate
						}
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: async function(){
			this.$emit('setProgram', this.program)
			this.clear()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					if(call){
						this.search.inquiryStartDate = call.replaceAll('-', '')
					}
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					if(call){
						this.search.inquiryEndDate = call.replaceAll('-', '')
					}
				}
			}
		}
	}
</script>

<style>
	#p20 .item-header .item .td:nth-child(1) { width: 3% !important;}
	#p20 .item-header .item .td:nth-child(2) { width: 7% !important;}
	#p20 .item-header .item .td:nth-child(3) { width: 10% !important;}
	#p20 .item-header .item .td:nth-child(4) { width: 15% !important;}
	#p20 .item-header .item .td:nth-child(5) { width: 6% !important;}
	#p20 .item-header .item .td:nth-child(6) { width: 10% !important;}
	#p20 .item-header .item .td:nth-child(7) { width: 8% !important;}
	#p20 .item-header .item .td:nth-child(8) { width: 5% !important;}
	#p20 .item-header .item .td:nth-child(9) { width: 8% !important;}
	#p20 .item-header .item .td:nth-child(10) { width: 10% !important;}
	#p20 .item-header .item .td:nth-child(11) { width: 7% !important;}
	#p20 .item-header .item .td:nth-child(12) { width: 5% !important;}
	#p20 .item-header .item .td:nth-child(13) { width: 6% !important;}
	
	#p20 .item-list .item .td:nth-child(1) { width: 3% !important;}
	#p20 .item-list .item .td:nth-child(2) { width: 7% !important;}
	#p20 .item-list .item .td:nth-child(3) { width: 10% !important; justify-content: start !important; padding-left: 10px !important;}
	#p20 .item-list .item .td:nth-child(4) { width: 15% !important; justify-content: start !important; padding-left: 10px !important;}
	#p20 .item-list .item .td:nth-child(5) { width: 6% !important;}
	#p20 .item-list .item .td:nth-child(6) { width: 10% !important;}
	#p20 .item-list .item .td:nth-child(7) { width: 8% !important; }
	#p20 .item-list .item .td:nth-child(8) { width: 5% !important;}
	#p20 .item-list .item .td:nth-child(9) { width: 8% !important;}
	#p20 .item-list .item .td:nth-child(10) { width: 10% !important;}
	#p20 .item-list .item .td:nth-child(11) { width: 7% !important; justify-content: center !important; padding: 0 !important;}
	#p20 .item-list .item .td:nth-child(12) { width: 5% !important;}
	#p20 .item-list .item .td:nth-child(13) { width: 6% !important;}
	
	#p20 .group .item { width: 100%;}
	#p20 .amount { font-size: 1em !important; margin-bottom: 20px;}
	#p20 .cnt { font-size: 1.5em !important; margin-right: 1px;}
</style>





















